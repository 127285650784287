import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'SchoolBagSearchPipe', pure: false })
export class SchoolBagSearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(folder => {
        if (folder.name || folder.customName) {
          return folder.name.search(searchText) !== -1 || folder.customName.search(searchText) !== -1;
        }
      });
    }
  }
}