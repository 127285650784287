import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Menu } from './menu.model';
import { verticalMenuItems, horizontalMenuItems } from './menu';

@Injectable()
export class MenuService {

  constructor(private location: Location) { }

  public getVerticalMenuItems(): Array<Menu> {
    let currentUserRole = localStorage.getItem('currentUserRole');
    let menuArray = [];

    if (location.hostname == 'ltm.edulinkin.com' ||
      location.hostname == 'ltmstage.edulinkin.com' ||
      location.hostname == 'ltmisa.edulinkin.com' ||
      location.hostname == 'ltmisastage.edulinkin.com') {
      if (currentUserRole == "Teacher") {
        menuArray = [
          new Menu(1, 'Exams', '/exams', null, 'account_balance', null, false, 0),
          new Menu(2, 'Mock exams', '/mocks', null, 'poll', null, false, 0),
          new Menu(3, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "SubTeacher") {
        menuArray = [
          new Menu(1, 'Exams', 'exams/published-exams', null, 'card_membership', null, false, 0),
          new Menu(2, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "Supervisor") {
        menuArray = [
          new Menu(1, 'Exams', '/exams', null, 'account_balance', null, false, 0),
          new Menu(2, 'Mock exams', '/mocks', null, 'poll', null, false, 0),
          new Menu(3, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
          new Menu(4, 'Published exams correction', 'exams/published-exams-correction', null, 'account_balance', null, false, 0),
          new Menu(5, 'Exam index', 'exams/exam-index', null, 'summarize', null, false, 0),
          new Menu(6, 'Subject index', 'exams/subject-index', null, 'summarize', null, false, 0),
          new Menu(7, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(8, 'Results by categories', 'exams/results-by-categories', null, 'summarize', null, false, 7),
          new Menu(9, 'Ranges on assessment', 'exams/ranges-on-assessment', null, 'summarize', null, false, 7),
          new Menu(10, 'Ranges on skills', 'exams/ranges-on-skills', null, 'summarize', null, false, 7),
          new Menu(11, 'Ranges on gender', 'exams/ranges-on-gender', null, 'summarize', null, false, 7),
          new Menu(12, 'Ranges on nationality', 'exams/ranges-on-nationality', null, 'summarize', null, false, 7),
          new Menu(13, 'Students results', 'exams/students-results', null, 'summarize', null, false, 7),
          new Menu(14, 'Question answers count', 'exams/questions-answers-count', null, 'summarize', null, false, 6),
          new Menu(15, 'Report migration', '/ltm/report-migration', null, 'poll', null, false, 0)
        ]
      }
      else if (currentUserRole == "SubSupervisor") {
        menuArray = [
          new Menu(1, 'Published exams', 'exams/published-exams', null, 'account_balance', null, false, 0),
          new Menu(2, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
          new Menu(5, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(6, 'Results by categories', 'exams/results-by-categories', null, 'summarize', null, false, 5),
          new Menu(7, 'Ranges on assessment', 'exams/ranges-on-assessment', null, 'summarize', null, false, 5),
          new Menu(8, 'Ranges on skills', 'exams/ranges-on-skills', null, 'summarize', null, false, 5),
          new Menu(9, 'Ranges on gender', 'exams/ranges-on-gender', null, 'summarize', null, false, 5),
          new Menu(10, 'Ranges on nationality', 'exams/ranges-on-nationality', null, 'summarize', null, false, 5),
          new Menu(11, 'Students results', 'exams/students-results', null, 'summarize', null, false, 5),
          new Menu(12, 'Question answers count', 'exams/questions-answers-count', null, 'summarize', null, false, 5)
        ]
      }
      else if (currentUserRole == "Parent") {
        menuArray = [
        ]
      }
      else if (currentUserRole == "Student") {
        menuArray = [
          new Menu(1, 'Exams and quizzes', 'exams/student-exams', null, 'card_membership', null, false, 0),
          new Menu(1, 'Mock exams', 'mocks/student-mocks-exams', null, 'poll', null, false, 0),
        ]
      }
      else if (currentUserRole == "SurveyCreator") {
        menuArray = [
          new Menu(1, 'Surveys', '/surveys', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'surveys/assign-management', null, 'account_balance', null, false, 0),
          new Menu(3, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(4, 'Surveys analysis', 'ridwan/survey-analysis', null, 'summarize', null, false, 3),
        ]
      }
      else if (currentUserRole == "EvaluationCreator") {
        menuArray = [
          new Menu(1, 'Evaluations', '/evaluations', null, 'poll', null, false, 0),
          new Menu(3, 'Assign management', 'evaluations/assign-management', null, 'account_balance', null, false, 0),
          new Menu(4, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(5, 'Supervisor evaluation', 'ridwan/supervisor-evaluation', null, 'summarize', null, false, 4),
        ]
      }
      else if (currentUserRole == "EvaluationAssigner") {
        menuArray = [
          new Menu(1, 'Published evaluations', 'evaluations/published-evaluations', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'evaluations/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "SurveyAssigner") {
        menuArray = [
          new Menu(1, 'Published surveys', 'surveys/published-surveys', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'surveys/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "SubscriptionAdmin") {
        menuArray = [
          //1-20 >>> School management
          new Menu(1, 'School management', null, null, 'computer', null, true, 0),
          new Menu(2, 'Schools', 'setups/services-management', null, 'keyboard', null, false, 1),
          new Menu(3, 'Curriculums', 'setups/curriculums-management', null, 'keyboard', null, false, 1),
          new Menu(4, 'Subjects', 'setups/subjects-management', null, 'keyboard', null, false, 1),
          new Menu(5, 'Constants', 'setups/constants', null, 'keyboard', null, false, 1),
          new Menu(6, 'Reports', 'setups/reports-management', null, 'summarize', null, false, 1),
          new Menu(7, 'Reports menu', 'exams/menu-index', null, 'summarize', null, false, 1),
          new Menu(8, 'Courses', 'setups/courses-management', null, 'keyboard', null, false, 1),
          new Menu(9, 'School bag settings', 'schoolBag/school-bag-settings', null, 'business_center', null, false, 1),

          //21-40 >>> Semesters management
          new Menu(21, 'Semesters management', null, null, 'computer', null, true, 0),
          new Menu(22, 'Semesters', 'setups/semesters-management', null, 'keyboard', null, false, 21),
          new Menu(23, 'Classes', 'setups/grades-classes-management', null, 'keyboard', null, false, 21),
          new Menu(24, 'Sectioning', null, null, 'computer', null, true, 21),
          new Menu(25, 'Students sectioning', 'setups/students-sectioning', null, 'keyboard', null, false, 24),
          new Menu(26, 'Teaching relations', 'setups/teaching-sectioning', null, 'keyboard', null, false, 24),
          new Menu(27, 'Supervisors relations', 'setups/supervisors-sectioning', null, 'keyboard', null, false, 24),
          new Menu(28, 'Managers relations', 'setups/managers-sectioning', null, 'keyboard', null, false, 24),
          new Menu(29, 'Courses sectioning', 'setups/course-students-sectioning', null, 'keyboard', null, false, 24),

          //41-60 >>> Users management
          new Menu(41, 'Users management', null, null, 'computer', null, true, 0),
          new Menu(42, 'Staff', 'setups/staff-management', null, 'keyboard', null, false, 41),
          new Menu(43, 'Students', 'setups/students-management', null, 'keyboard', null, false, 41),
          new Menu(44, 'Grades - Students', 'setups/grades-students-management', null, 'keyboard', null, false, 41),

          //61-80 >>> Evaluations management
          new Menu(61, 'Evaluations', 'evaluations/evaluations-management', null, 'rule', null, false, 0),
        ]

      }
      else if (currentUserRole == "BankCreator") {
        menuArray = [
          new Menu(1, 'Questions Bank', 'exams/questions-bank', null, 'account_balance', null, false, 0),
        ]
      }
    }
    else if (location.hostname == 'ridwan.edulinkin.com' ||
      location.hostname == 'ridwanprod.edulinkin.com' ||
      location.hostname == 'ridwanstage.edulinkin.com' ||
      location.hostname == 'exam.edulinkin.com' ||
      location.hostname == 'demo.edulinkin.com' ||
      location.hostname == 'localhost') {
      if (currentUserRole == "Teacher") {
        menuArray = [
          new Menu(1, 'Teacher panel', null, null, 'account_circle', null, true, 0),
          new Menu(2, 'Students evaluations', 'evaluations/evaluations-class-subsubject', null, 'rule', null, false, 1),
          new Menu(3, 'Exams', null, null, 'computer', null, true, 0),
          new Menu(4, 'Exams', '/exams', null, 'account_balance', null, false, 3),
          new Menu(5, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 3),
          new Menu(6, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
          new Menu(7, 'Evaluations', 'evaluations/targeted-evaluations', null, 'poll', null, false, 0),
          new Menu(8, 'School bag', 'schoolBag/school-bag', null, 'business_center', null, false, 0),
          new Menu(9, 'Daily attendance', 'dailyAttendance/daily-attendance', null, 'calendar_month', null, false, 0),
        ]
      }
      else if (currentUserRole == "SubTeacher") {
        menuArray = [
          new Menu(1, 'Exams', 'exams/published-exams', null, 'card_membership', null, false, 0),
          new Menu(2, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "Supervisor") {
        menuArray = [
          new Menu(1, 'Exams', null, null, 'computer', null, true, 0),
          new Menu(2, 'Exams', '/exams', null, 'account_balance', null, false, 1),
          new Menu(3, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 1),
          new Menu(4, 'Published exams correction', 'exams/published-exams-correction', null, 'account_balance', null, false, 1),
          new Menu(5, 'Exam index', 'exams/exam-index', null, 'summarize', null, false, 1),
          new Menu(6, 'Subject index', 'exams/subject-index', null, 'summarize', null, false, 1),
          new Menu(8, 'School bag', 'schoolBag/school-bag', null, 'business_center', null, false, 0),

          new Menu(7, 'Students evaluations', null, null, 'computer', null, true, 0),
          new Menu(8, 'Evaluations approval', 'evaluations/evaluations-class-subsubject', null, 'rule', null, false, 7),

          new Menu(9, 'Teachers evaluations', null, null, 'computer', null, true, 0),
          new Menu(10, 'Evaluations', 'evaluations/assigned-evaluations', null, 'poll', null, false, 9),
          new Menu(11, 'Evaluations to targets', 'evaluations/assigned-targeted-evaluations', null, 'poll', null, false, 9),
          new Menu(4, 'Evaluations correction', 'evaluations/published-evaluations-correction', null, 'account_balance', null, false, 9),

          new Menu(12, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),

          new Menu(13, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(14, 'Supervisor evaluation', 'ridwan/supervisor-evaluation', null, 'summarize', null, false, 13),

          // new Menu(14, 'Results by categories', 'exams/results-by-categories', null, 'summarize', null, false, 13),
          // new Menu(15, 'Ranges on assessment', 'exams/ranges-on-assessment', null, 'summarize', null, false, 13),
          // new Menu(16, 'Ranges on skills', 'exams/ranges-on-skills', null, 'summarize', null, false, 13),
          // new Menu(17, 'Ranges on gender', 'exams/ranges-on-gender', null, 'summarize', null, false, 13),
          // new Menu(18, 'Ranges on nationality', 'exams/ranges-on-nationality', null, 'summarize', null, false, 13),
          // new Menu(19, 'Students results', 'exams/students-results', null, 'summarize', null, false, 13),
          // new Menu(20, 'Question answers count', 'exams/questions-answers-count', null, 'summarize', null, false, 13),

        ]
      }
      else if (currentUserRole == "SubSupervisor") {
        menuArray = [
          new Menu(1, 'Published exams', 'exams/published-exams', null, 'account_balance', null, false, 0),
          new Menu(2, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
          new Menu(5, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(6, 'Results by categories', 'exams/results-by-categories', null, 'summarize', null, false, 5),
          new Menu(7, 'Ranges on assessment', 'exams/ranges-on-assessment', null, 'summarize', null, false, 5),
          new Menu(8, 'Ranges on skills', 'exams/ranges-on-skills', null, 'summarize', null, false, 5),
          new Menu(9, 'Ranges on gender', 'exams/ranges-on-gender', null, 'summarize', null, false, 5),
          new Menu(10, 'Ranges on nationality', 'exams/ranges-on-nationality', null, 'summarize', null, false, 5),
          new Menu(11, 'Students results', 'exams/students-results', null, 'summarize', null, false, 5),
          new Menu(12, 'Question answers count', 'exams/questions-answers-count', null, 'summarize', null, false, 5)
        ]
      }
      else if (currentUserRole == "Manager") {
        menuArray = [
          new Menu(1, 'Students evaluations', null, null, 'computer', null, true, 0),
          new Menu(2, 'Evaluations approval', 'evaluations/evaluations-class-subsubject', null, 'rule', null, false, 1),
          new Menu(3, 'School bag', 'schoolBag/school-bag', null, 'business_center', null, false, 0),
        ]
      }
      else if (currentUserRole == "Parent") {
        menuArray = [
          new Menu(1, 'School bag', 'schoolBag/school-bag', null, 'business_center', null, false, 0),
          new Menu(2, 'Evaluations results', 'evaluations/evaluations-results', null, 'poll', null, false, 0),
          new Menu(3, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
          new Menu(4, 'Dentist form', 'ridwan/dentist-form', null, 'poll', null, false, 0),
        ]
      }
      else if (currentUserRole == "Student") {
        menuArray = [
          new Menu(1, 'School bag', 'schoolBag/school-bag', null, 'business_center', null, false, 0),
          new Menu(2, 'Evaluations results', 'evaluations/evaluations-results', null, 'poll', null, false, 0),
          new Menu(3, 'Exams and quizzes', 'exams/student-exams', null, 'card_membership', null, false, 0),
          new Menu(4, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
        ]
      }
      else if (currentUserRole == "SurveyCreator") {
        menuArray = [
          new Menu(1, 'Surveys', '/surveys', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'surveys/assign-management', null, 'account_balance', null, false, 0),
          new Menu(3, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(4, 'Surveys analysis', 'ridwan/survey-analysis', null, 'summarize', null, false, 3),
        ]
      }
      else if (currentUserRole == "EvaluationCreator") {
        menuArray = [
          new Menu(1, 'Evaluations', '/evaluations', null, 'poll', null, false, 0),
          new Menu(3, 'Assign management', 'evaluations/assign-management', null, 'account_balance', null, false, 0),
          new Menu(4, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(5, 'Supervisor evaluation', 'ridwan/supervisor-evaluation', null, 'summarize', null, false, 4),
        ]
      }
      else if (currentUserRole == "EvaluationAssigner") {
        menuArray = [
          new Menu(1, 'Published evaluations', 'evaluations/published-evaluations', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'evaluations/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "SurveyAssigner") {
        menuArray = [
          new Menu(1, 'Published surveys', 'surveys/published-surveys', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'surveys/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "SubscriptionAdmin") {
        menuArray = [
          //1-20 >>> School management
          new Menu(1, 'School management', null, null, 'computer', null, true, 0),
          new Menu(2, 'Schools', 'setups/services-management', null, 'keyboard', null, false, 1),
          new Menu(3, 'Curriculums', 'setups/curriculums-management', null, 'keyboard', null, false, 1),
          new Menu(4, 'Subjects', 'setups/subjects-management', null, 'keyboard', null, false, 1),
          new Menu(5, 'Constants', 'setups/constants', null, 'keyboard', null, false, 1),
          new Menu(6, 'Reports', 'setups/reports-management', null, 'summarize', null, false, 1),
          new Menu(7, 'Reports menu', 'exams/menu-index', null, 'summarize', null, false, 1),
          new Menu(8, 'Courses', 'setups/courses-management', null, 'keyboard', null, false, 1),
          new Menu(9, 'School bag settings', 'schoolBag/school-bag-settings', null, 'business_center', null, false, 1),

          //21-40 >>> Semesters management
          new Menu(21, 'Semesters management', null, null, 'computer', null, true, 0),
          new Menu(22, 'Semesters', 'setups/semesters-management', null, 'keyboard', null, false, 21),
          new Menu(23, 'Classes', 'setups/grades-classes-management', null, 'keyboard', null, false, 21),
          new Menu(24, 'Sectioning', null, null, 'computer', null, true, 21),
          new Menu(25, 'Students sectioning', 'setups/students-sectioning', null, 'keyboard', null, false, 24),
          new Menu(26, 'Teaching relations', 'setups/teaching-sectioning', null, 'keyboard', null, false, 24),
          new Menu(27, 'Supervisors relations', 'setups/supervisors-sectioning', null, 'keyboard', null, false, 24),
          new Menu(28, 'Managers relations', 'setups/managers-sectioning', null, 'keyboard', null, false, 24),
          new Menu(29, 'Courses sectioning', 'setups/course-students-sectioning', null, 'keyboard', null, false, 24),

          //41-60 >>> Users management
          new Menu(41, 'Users management', null, null, 'computer', null, true, 0),
          new Menu(42, 'Staff', 'setups/staff-management', null, 'keyboard', null, false, 41),
          new Menu(43, 'Students', 'setups/students-management', null, 'keyboard', null, false, 41),
          new Menu(44, 'Grades - Students', 'setups/grades-students-management', null, 'keyboard', null, false, 41),

          //61-80 >>> Evaluations management
          new Menu(61, 'Evaluations', 'evaluations/evaluations-management', null, 'rule', null, false, 0),

          //81-100 >>> Daily Attendance Settings
          new Menu(81, 'Daily attendance settings', 'dailyAttendance/daily-attendance-settings', null, 'rule', null, false, 0),
        ]
      }
      else if (currentUserRole == "BankCreator") {
        menuArray = [
          new Menu(1, 'Questions Bank', 'exams/questions-bank', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "Doctor") {
        menuArray = [
          new Menu(1, 'Dentist', 'ridwan/dentist-form', null, 'poll', null, false, 0),
        ]
      }
    }
    else if (
      location.hostname == 'alpha.edulinkin.com' ||
      location.hostname == 'beta.edulinkin.com' ||
      location.hostname == 'release.edulinkin.com') {
      if (currentUserRole == "Teacher") {
        menuArray = [
          new Menu(1, 'Teacher panel', null, null, 'account_circle', null, true, 0),
          new Menu(2, 'Students evaluations', 'evaluations/evaluations-class-subsubject', null, 'rule', null, false, 1),
          new Menu(3, 'Exams', null, null, 'computer', null, true, 0),
          new Menu(4, 'Exams', '/exams', null, 'account_balance', null, false, 3),
          new Menu(5, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 3),
          new Menu(6, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
          new Menu(7, 'Evaluations', 'evaluations/targeted-evaluations', null, 'poll', null, false, 0),
          new Menu(8, 'School bag', 'schoolBag/school-bag', null, 'business_center', null, false, 0),
          new Menu(9, 'Daily attendance', 'dailyAttendance/daily-attendance', null, 'calendar_month', null, false, 0),
        ]
      }
      else if (currentUserRole == "SubTeacher") {
        menuArray = [
          new Menu(1, 'Exams', 'exams/published-exams', null, 'card_membership', null, false, 0),
          new Menu(2, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "Supervisor") {
        menuArray = [
          new Menu(1, 'Exams', null, null, 'computer', null, true, 0),
          new Menu(2, 'Exams', '/exams', null, 'account_balance', null, false, 1),
          new Menu(3, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 1),
          new Menu(4, 'Published exams correction', 'exams/published-exams-correction', null, 'account_balance', null, false, 1),
          new Menu(5, 'Exam index', 'exams/exam-index', null, 'summarize', null, false, 1),
          new Menu(6, 'Subject index', 'exams/subject-index', null, 'summarize', null, false, 1),
          new Menu(8, 'School bag', 'schoolBag/school-bag', null, 'business_center', null, false, 0),

          new Menu(7, 'Students evaluations', null, null, 'computer', null, true, 0),
          new Menu(8, 'Evaluations approval', 'evaluations/evaluations-class-subsubject', null, 'rule', null, false, 7),

          new Menu(9, 'Teachers evaluations', null, null, 'computer', null, true, 0),
          new Menu(10, 'Evaluations', 'evaluations/assigned-evaluations', null, 'poll', null, false, 9),
          new Menu(11, 'Evaluations to targets', 'evaluations/assigned-targeted-evaluations', null, 'poll', null, false, 9),

          new Menu(12, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),

          new Menu(13, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(14, 'Supervisor evaluation', 'ridwan/supervisor-evaluation', null, 'summarize', null, false, 13),
          // new Menu(14, 'Results by categories', 'exams/results-by-categories', null, 'summarize', null, false, 13),
          // new Menu(15, 'Ranges on assessment', 'exams/ranges-on-assessment', null, 'summarize', null, false, 13),
          // new Menu(16, 'Ranges on skills', 'exams/ranges-on-skills', null, 'summarize', null, false, 13),
          // new Menu(17, 'Ranges on gender', 'exams/ranges-on-gender', null, 'summarize', null, false, 13),
          // new Menu(18, 'Ranges on nationality', 'exams/ranges-on-nationality', null, 'summarize', null, false, 13),
          // new Menu(19, 'Students results', 'exams/students-results', null, 'summarize', null, false, 13),
          // new Menu(20, 'Question answers count', 'exams/questions-answers-count', null, 'summarize', null, false, 13),

        ]
      }
      else if (currentUserRole == "SubSupervisor") {
        menuArray = [
          new Menu(1, 'Published exams', 'exams/published-exams', null, 'account_balance', null, false, 0),
          new Menu(2, 'Assign management', 'exams/assign-management', null, 'account_balance', null, false, 0),
          new Menu(5, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(6, 'Results by categories', 'exams/results-by-categories', null, 'summarize', null, false, 5),
          new Menu(7, 'Ranges on assessment', 'exams/ranges-on-assessment', null, 'summarize', null, false, 5),
          new Menu(8, 'Ranges on skills', 'exams/ranges-on-skills', null, 'summarize', null, false, 5),
          new Menu(9, 'Ranges on gender', 'exams/ranges-on-gender', null, 'summarize', null, false, 5),
          new Menu(10, 'Ranges on nationality', 'exams/ranges-on-nationality', null, 'summarize', null, false, 5),
          new Menu(11, 'Students results', 'exams/students-results', null, 'summarize', null, false, 5),
          new Menu(12, 'Question answers count', 'exams/questions-answers-count', null, 'summarize', null, false, 5)
        ]
      }
      else if (currentUserRole == "Manager") {
        menuArray = [
          new Menu(1, 'Students evaluations', null, null, 'computer', null, true, 0),
          new Menu(2, 'Evaluations approval', 'evaluations/evaluations-class-subsubject', null, 'rule', null, false, 1),
          new Menu(3, 'School bag', 'schoolBag/school-bag', null, 'business_center', null, false, 0),
        ]
      }
      else if (currentUserRole == "Parent") {
        menuArray = [
          new Menu(1, 'School bag', 'schoolBag/school-bag', null, 'business_center', null, false, 0),
          new Menu(2, 'Evaluations results', 'evaluations/evaluations-results', null, 'poll', null, false, 0),
          new Menu(3, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
          new Menu(4, 'Dentist form', 'ridwan/dentist-form', null, 'poll', null, false, 0),
        ]
      }
      else if (currentUserRole == "Student") {
        menuArray = [
          new Menu(1, 'School bag', 'schoolBag/school-bag', null, 'business_center', null, false, 0),
          new Menu(2, 'Evaluations results', 'evaluations/evaluations-results', null, 'poll', null, false, 0),
          new Menu(3, 'Exams and quizzes', 'exams/student-exams', null, 'card_membership', null, false, 0),
          new Menu(4, 'Surveys', 'surveys/assigned-surveys', null, 'poll', null, false, 0),
        ]
      }
      else if (currentUserRole == "SurveyCreator") {
        menuArray = [
          new Menu(1, 'Surveys', '/surveys', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'surveys/assign-management', null, 'account_balance', null, false, 0),
          new Menu(3, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(4, 'Surveys analysis', 'ridwan/survey-analysis', null, 'summarize', null, false, 3),
        ]
      }
      else if (currentUserRole == "EvaluationCreator") {
        menuArray = [
          new Menu(1, 'Evaluations', '/evaluations', null, 'poll', null, false, 0),
          new Menu(3, 'Assign management', 'evaluations/assign-management', null, 'account_balance', null, false, 0),
          new Menu(4, 'Reports', null, null, 'computer', null, true, 0),
          new Menu(5, 'Supervisor evaluation', 'ridwan/supervisor-evaluation', null, 'summarize', null, false, 4),
        ]
      }
      else if (currentUserRole == "EvaluationAssigner") {
        menuArray = [
          new Menu(1, 'Published evaluations', 'evaluations/published-evaluations', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'evaluations/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "SurveyAssigner") {
        menuArray = [
          new Menu(1, 'Published surveys', 'surveys/published-surveys', null, 'poll', null, false, 0),
          new Menu(2, 'Assign management', 'surveys/assign-management', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "SubscriptionAdmin") {
        menuArray = [
          //1-20 >>> School management
          new Menu(1, 'School management', null, null, 'computer', null, true, 0),
          new Menu(2, 'Schools', 'setups/services-management', null, 'keyboard', null, false, 1),
          new Menu(3, 'Curriculums', 'setups/curriculums-management', null, 'keyboard', null, false, 1),
          new Menu(4, 'Subjects', 'setups/subjects-management', null, 'keyboard', null, false, 1),
          new Menu(5, 'Constants', 'setups/constants', null, 'keyboard', null, false, 1),
          new Menu(6, 'Reports', 'setups/reports-management', null, 'summarize', null, false, 1),
          new Menu(7, 'Reports menu', 'exams/menu-index', null, 'summarize', null, false, 1),
          new Menu(8, 'Courses', 'setups/courses-management', null, 'keyboard', null, false, 1),
          new Menu(9, 'School bag settings', 'schoolBag/school-bag-settings', null, 'business_center', null, false, 1),

          //21-40 >>> Semesters management
          new Menu(21, 'Semesters management', null, null, 'computer', null, true, 0),
          new Menu(22, 'Semesters', 'setups/semesters-management', null, 'keyboard', null, false, 21),
          new Menu(23, 'Classes', 'setups/grades-classes-management', null, 'keyboard', null, false, 21),
          new Menu(24, 'Sectioning', null, null, 'computer', null, true, 21),
          new Menu(25, 'Students sectioning', 'setups/students-sectioning', null, 'keyboard', null, false, 24),
          new Menu(26, 'Teaching relations', 'setups/teaching-sectioning', null, 'keyboard', null, false, 24),
          new Menu(27, 'Supervisors relations', 'setups/supervisors-sectioning', null, 'keyboard', null, false, 24),
          new Menu(28, 'Managers relations', 'setups/managers-sectioning', null, 'keyboard', null, false, 24),
          new Menu(29, 'Courses sectioning', 'setups/course-students-sectioning', null, 'keyboard', null, false, 24),

          //41-60 >>> Users management
          new Menu(41, 'Users management', null, null, 'computer', null, true, 0),
          new Menu(42, 'Staff', 'setups/staff-management', null, 'keyboard', null, false, 41),
          new Menu(43, 'Students', 'setups/students-management', null, 'keyboard', null, false, 41),
          new Menu(44, 'Grades - Students', 'setups/grades-students-management', null, 'keyboard', null, false, 41),

          //61-80 >>> Evaluations management
          new Menu(61, 'Evaluations', 'evaluations/evaluations-management', null, 'rule', null, false, 0),

          //81-100 >>> Daily Attendance Settings
          new Menu(81, 'Daily attendance settings', 'dailyAttendance/daily-attendance-settings', null, 'rule', null, false, 0),
        ]
      }
      else if (currentUserRole == "BankCreator") {
        menuArray = [
          new Menu(1, 'Questions Bank', 'exams/questions-bank', null, 'account_balance', null, false, 0),
        ]
      }
      else if (currentUserRole == "Doctor") {
        menuArray = [
          new Menu(1, 'Dentist', 'ridwan/dentist-form', null, 'poll', null, false, 0),
        ]
      }
    }

    return menuArray;
  }

  public getHorizontalMenuItems(): Array<Menu> {
    let currentUserRole = localStorage.getItem('currentUserRole');
    let menuArray = [];
    return menuArray;
  }

  public expandActiveSubMenu(menu: Array<Menu>) {
    let url = this.location.path();
    let routerLink = url; // url.substring(1, url.length);
    let activeMenuItem = menu.filter(item => item.routerLink === routerLink);
    if (activeMenuItem[0]) {
      let menuItem = activeMenuItem[0];
      while (menuItem.parentId != 0) {
        let parentMenuItem = menu.filter(item => item.id == menuItem.parentId)[0];
        menuItem = parentMenuItem;
        this.toggleMenuItem(menuItem.id);
      }
    }
  }

  public toggleMenuItem(menuId) {
    let menuItem = document.getElementById('menu-item-' + menuId);
    let subMenu = document.getElementById('sub-menu-' + menuId);
    if (subMenu) {
      if (subMenu.classList.contains('show')) {
        subMenu.classList.remove('show');
        menuItem.classList.remove('expanded');
      }
      else {
        subMenu.classList.add('show');
        menuItem.classList.add('expanded');
      }
    }
  }

  public closeOtherSubMenus(menu: Array<Menu>, menuId) {
    let currentMenuItem = menu.filter(item => item.id == menuId)[0];
    if (currentMenuItem.parentId == 0 && !currentMenuItem.target) {
      menu.forEach(item => {
        if (item.id != menuId) {
          let subMenu = document.getElementById('sub-menu-' + item.id);
          let menuItem = document.getElementById('menu-item-' + item.id);
          if (subMenu) {
            if (subMenu.classList.contains('show')) {
              subMenu.classList.remove('show');
              menuItem.classList.remove('expanded');
            }
          }
        }
      });
    }
  }

  public closeAllSubMenus() {
    let menu = document.getElementById("vertical-menu");
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }


}
